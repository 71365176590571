export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Captured By",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status Seller",
    model: null,
    reduce: "value",
    selectText: "label",
    cols: 5,
    emitEvent: true,
    typeEvent: "status_seller",
    visible: false,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Source Name",
    multiple: true,
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Programs",
    model: null,
    multiple: true,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "IP",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "ST/AD",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    visible: false,
  },
];
