export default [
  {
    key: "client",
    label: "Client",
    sortable: true,
  },
  {
    key: "program",
    label: "Program",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: false,
  },
  {
    key: "captured",
    label: "Captured By",
    sortable: false,
  },
  {
    key: "seller",
    label: "Seller",
    sortable: false,
  },
  {
    key: "fee",
    label: "Fee ($)",
    sortable: false,
  },
  {
    key: "initial_amount",
    tdClass: "text-center",
    thClass: "text-center",
    label: "IP",
    sortable: false,
  },
  {
    key: "contract_fee_status",
    tdClass: "text-center",
    thClass: "text-center",
    label: "CF",
    sortable: false,
  },
  {
    key: "notes_status",
    tdClass: "text-center",
    thClass: "text-center",
    label: "NT",
    sortable: false,
  },
  {
    key: "trackings",
    tdClass: "text-center",
    thClass: "text-center",
    label: "TK",
    sortable: false,
  },
  {
    key: "files",
    tdClass: "text-center",
    thClass: "text-center",
    label: "FI",
    sortable: false,
  },
  {
    key: "status",
    label: "Status",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: false,
  },
  {
    key: "actions",
    label: "Action",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: false,
  },
  {
    key: "creates",
    sortable: true,
    tdClass: "text-center",
    thClass: "text-center",
    label: "Created",
  },
  {
    key: "approved",
    label: "Approved",
    tdClass: "text-center",
    thClass: "text-center",
    sortable: true,
  },
];
